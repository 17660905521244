<template>
  <div class="ndj-lottery">
    <titlebar :titleName="activity_name"</titlebar>
    <div class="content1" :style="{ height: '245vw' }">
      <div class="component_popup" v-if="islogin === 2">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <login @isLogin="isLogin" @getNum="getNum"></login>
        </div>
      </div>
      <div class="ndjslogan"></div>
      <div class="ndjcontent"></div>
      <div class="ndjprizelayout">
        <transition name="slide">
          <div class="ndjprize" v-if="show"></div>
        </transition>
      </div>
      <div class="ndjbtn" @click="prizeAnimate"></div>
      <div class="ndjnum">
        <p class="text">剩余次数：{{ num }}</p>
      </div>
      <div
        class="ndjnum"
        style="margin-left: 167.9px"
        @click="
          showRecord();
          getPrizeRecord();
        "
      >
        <p class="text">我的中奖记录</p>
      </div>
      <!-- 中奖记录 -->
      <div class="component_popup" v-if="isshowRecord" @click="showRecord">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <div class="content_box">
            <ol class="prize_list">
              <li
                class="prize_record"
                v-for="(item, index) in prizeRecord"
                :key="item.add_time"
              >
                {{ item.prize_name }} {{ item.add_time }}
              </li>
            </ol>
          </div>
          <div class="content_close" @click="showRecord"></div>
        </div>
      </div>
      <!-- 得奖弹窗 -->
      <div class="component_popup" v-if="isshowPrize" @click="showPrize">
        <div class="popup_mask"></div>
        <div class="popup_content">
          <div class="content_box2">
            <div class="prize_photo">
              <img :src="imgUrl" width="284px" height="215px" />
            </div>
            <div class="prize_name">{{ prize_name }}</div>
          </div>
          <div class="content_close2" @click="showPrize"></div>
        </div>
      </div>
      <!-- 无奖弹窗 -->
      <div class="component_popup" v-if="isshowNoPrize">
        <div class="popup_mask" @click="showNoPrize"></div>
        <div class="popup_content">
          <div class="content_box3">
            <div class="noprize_btn" @click="showNoPrize"></div>
            <div class="noprize_text">很遗憾，大奖与您擦肩而过</div>
          </div>
        </div>
      </div>
      <!-- 活动规则 -->
      <div class="board">
        <div class="text" v-html="rule"></div>
      </div>
      <!-- 登录 -->
      <div class="logintext">
        <span v-if="$cookies.get('uname')">你好，{{ $cookies.get("muname") }} </span>
        <span v-else @click="login">你好，请 登录</span>
        <span v-if="$cookies.get('uname')" @click="out"> 退出</span>
      </div>
      <!-- 闪灯动画 -->
      <div class="number-set" v-if="isshowAnimate">
        <div class="ball-container-1">
          <div class="ball build-in-1"></div>
        </div>
        <div class="ball-container-2">
          <div class="ball build-in-2"></div>
        </div>
        <div class="ball-container-3">
          <div class="ball build-in-3"></div>
        </div>
        <div class="ball-container-4">
          <div class="ball build-in-4"></div>
        </div>
        <div class="ball-container-5">
          <div class="ball build-in-5"></div>
        </div>
        <div class="ball-container-6">
          <div class="ball build-in-6"></div>
        </div>
        <div class="ball-container-7">
          <div class="ball build-in-7"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titlebar from "../components/index/titleBar.vue";
import apiFun from "../servers/apiFun";
import comTool from "../assets/js/until";
import login from "./login";

export default {
  components: {
    titlebar,
    login,
  },
  data() {
    return {
      // mmh: "",
      index: Number,
      timer: null,
      show: false,
      isStart: false,
      isEnd: false,
      isshowAnimate: false,
      data: {},
      islogin: 0,
      draw_type_id: 1,
      activity_id: 1,
      isshowPrize: false,
      isshowRecord: false,
      isshowNoPrize: false,
      prize_name: "",
      rule: "",
      imgUrl: "",
      prizeRecord: "",
      activity_name: "",
      num: $cookies.get("num"),
      selectedPrize: [],
      lucky: [
        { prize: "0" },
        { prize: "1" },
        { prize: "2" },
        { prize: "3" },
        { prize: "4" },
        { prize: "5" },
        { prize: "6" },
        { prize: "7" },
        { prize: "8" },
        { prize: "9" },
      ],
    };
  },
  filters: {
    replacePrize(str) {
      if (str === "") return "";
      return str.substr();
    },
  },
  created() {
    if (this.$route.query.access_token) {
      this.client = "android";
      this.sdkInit();
    } else {
      this.client = "h5";
      this.init();
    }
    if ($cookies.get("uname")) {
      this.islogin = 1;
    }
    // this.index = Math.floor(Math.random() * 8) + 1;
    // this.mmh = document.documentElement.clientHeight + "px";
  },
  methods: {
    //正常初始化
    init() {
      let data = {
        activity_id: this.$route.query.activity_id ? this.$route.query.activity_id : "1",
      };
      apiFun.Init(data).then((res) => {
        if (res.code == 200) {
          this.rule = res.data.activity.content;
          $cookies.set("activity_id", res.data.activity.activity_id);
          $cookies.set("draw_type_id", res.data.activity.draw_type_id);
          this.activity_id = res.data.activity.activity_id;
          this.draw_type_id = res.data.activity.draw_type_id;
          this.activity_name = res.data.activity.activity_name;
          console.log("activity_name: ", this.activity_name);
          if (res.data.activity.draw_type_id == "1") {
            this.$router.push({
              path: "/lottery",
              query: { activity_id: $cookies.get("activity_id") },
            });
          } else if (res.data.activity.draw_type_id == "2") {
            this.$router.push({
              path: "/lotteryjgg",
              query: { activity_id: $cookies.get("activity_id") },
            });
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //sdk初始化
    sdkInit() {
      $cookies.set("pid", this.$route.query.pid, "7d");
      $cookies.set("gid", this.$route.query.gid, "7d");
      $cookies.set("access_token", this.$route.query.access_token, "7d");
      $cookies.set("uname", this.$route.query.uname, "7d");
      this.init();
      this.sdkDrawTimes();
    },
    //sdk获取抽奖次数
    sdkDrawTimes() {
      let data = {
        pid: $cookies.get("pid"),
        gid: $cookies.get("gid"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        activity_id: this.activity_id,
        client: this.client,
      };
      apiFun.SdkDrawTimes(data).then((res) => {
        $cookies.set("num", res.data.count, "7d");
      });
    },
    //扭蛋机抽奖
    startRandom() {
      this.num -= 1;
      this.isStart = true;
      // 清除定时器
      clearInterval(this.timer);
      this.getData();
      // 开启定时器
      this.timer = setTimeout(() => {
        let length = this.lucky.length;
        let num = Math.floor(Math.random() * length);
        this.selectedPrize = this.lucky[num];
        this.show = false;
        if ($cookies.get("id") == "4") {
          this.isshowNoPrize = true;
        } else {
          this.isshowPrize = true;
        }
      }, 4000);
    },
    prizeAnimate() {
      if (
        this.num > 0 &&
        this.isshowRecord === false &&
        this.show === false &&
        this.isshowPrize === false &&
        this.islogin === 1
      ) {
        this.isshowAnimate = true;
        clearInterval(this.timer);
        this.timer = setTimeout(() => {
          this.isshowAnimate = false;
          this.show = true;
          this.startRandom();
        }, 2000);
      } else if (
        this.isshowRecord === false &&
        this.isshowPrize === false &&
        this.islogin === 0
      ) {
        this.login();
      } else if (this.num == 0 && this.islogin === 1) {
        this.$toast.fail("剩余抽奖次数为 0 ，不能再抽奖了哦");
      }
    },
    //抽奖接口
    getData() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        draw_type_id: this.draw_type_id,
        uname: $cookies.get("uname"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        activity_id: this.activity_id,
        client: this.client,
      };
      apiFun.Start(data).then((res) => {
        if (res.code == 200) {
          this.prize_name = res.data.prize_name;
          $cookies.set("id", res.data.id);
          this.imgUrl = res.data.prize_url;
          $cookies.set("num", res.data.draw_times);
        } else {
          this.$toast.fail(res.msg);
          this.prize_name = res.msg;
          $cookies.set("id", 4);
        }
      });
    },
    //抽奖记录接口
    getPrizeRecord() {
      let data = {
        gid: $cookies.get("gid"),
        pid: $cookies.get("pid"),
        access_token:
          $cookies.get("access_token") == null
            ? $cookies.get("user_token")
            : $cookies.get("access_token"),
        activity_id: this.activity_id,
        client: this.client,
        uname: $cookies.get("uname"),
      };
      apiFun.PrizeRecord(data).then((res) => {
        if (res.code == 200) {
          this.prizeRecord = res.data;
        }
      });
    },
    //控制方法
    showRecord() {
      this.isshowRecord = !this.isshowRecord;
    },
    showPrize() {
      this.isshowPrize = !this.isshowPrize;
    },
    showNoPrize() {
      this.isshowNoPrize = !this.isshowNoPrize;
      console.log("isshowNoPrize", this.isshowNoPrize);
    },
    login() {
      this.islogin = 2;
    },
    isLogin(data) {
      this.islogin = data;
    },
    getNum(data) {
      this.num = data;
      console.log("num=", this.num);
    },
    out() {
      $cookies.remove("access_token");
      $cookies.remove("user_token");
      $cookies.remove("uname");
      $cookies.remove("uid");
      $cookies.remove("pid");
      $cookies.remove("gid");
      $cookies.remove("mobile");
      $cookies.remove("realName");
      $cookies.remove("idNumber");
      $cookies.remove("num");
      this.$router.go(0);
    },
  },
};
</script>

<style>
.van-toast {
  width: 800px;
  min-height: 200px;
  line-height: 100px;
}
</style>
<style src="../assets/css/animation.css" scoped></style>
<style lang="less" scoped>
.ndj-lottery {
  overflow: hidden;
}
.content1 {
  margin-top: 13vw;
  background-image: url("../assets/images/ndjbanner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  image-rendering: -webkit-optimize-contrast;
  .text1 {
    position: absolute;
    font-family: Source Han Sans CN;
    color: #6098d1;
    font-size: 54px;
    font-weight: bold;
    width: 341px;
    top: 788px;
    left: 71px;
  }
  .ndjslogan {
    position: relative;
    width: 1105px;
    height: 279px;
    background-image: url("../assets/images/ndj幸运大抽奖.png");
    background-size: 1105px 279px;
    top: 69px;
    margin: auto;
  }
  .ndjcontent {
    width: 1156px;
    height: 1603px;
    background-image: url("../assets/images/ndj.png");
    background-repeat: no-repeat;
    background-size: 1156px 1603px;
    position: relative;
    top: 33px;
    left: 83px;
  }
  .ndjprizelayout {
    position: relative;
    top: -13vw;
    left: 27.5vw;
    overflow: hidden;
    .ndjprize {
      width: 624px;
      height: 304px;
      background-image: url("../assets/images/ndjprize.png");
      background-repeat: no-repeat;
      background-size: 624px 304px;
      position: relative;
      top: -23vw;
    }
  }
}
.ndjnum {
  background-image: url("../assets/images/ndjnum.png");
  background-size: 491.1px 172.1px;
  width: 491.1px;
  height: 172.1px;
  line-height: 55px;
  position: absolute;
  display: inline-block;
  top: 2188px;
  left: 128px;
  .text {
    position: relative;
    left: 81px;
    color: #ffffff;
    font-size: 57px;
    font-weight: bold;
    color: #ffffff;
  }
}
.ndjbtn {
  width: 344.8px;
  height: 315px;
  position: absolute;
  border-radius: 50%;
  top: 1294px;
  left: 470px;
  z-index: 10;
}
.board {
  position: absolute;
  background-image: url("../assets/images/ndjboard.png");
  background-size: 1191px 881px;
  width: 1191px;
  height: 881px;
  line-height: 91px;
  top: 2368px;
  left: 47px;
  .text {
    position: relative;
    font-family: Source Han Sans CN;
    color: #dc1912;
    top: 142px;
    left: 107px;
    font-size: 41px;
    font-weight: 550;
  }
}
.component_popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.popup_mask {
  background-color: #000000;
  opacity: 0.79;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.popup_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content_box {
  background-image: url("../assets/images/prizerecord.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_list {
    position: relative;
    width: 660px;
    top: 540px;
    margin: 0 auto;
    height: 553px;
    overflow-x: scroll;
    overflow-y: scroll;
    list-style: decimal inside;
    .prize_record {
      top: 380px;
      left: 20%;
      margin: 60px auto;
      font-size: 46px;
      font-family: PingFang SC;
      color: #ffffff;
    }
  }
}
.content_close {
  width: 70%;
  height: 200px;
  margin: 0 auto;
}
.content_box2 {
  background-image: url("../assets/images/prize.png");
  background-size: 1003px 1382px;
  width: 1003px;
  height: 1382px;
  .prize_name {
    position: relative;
    top: 100px;
    left: 40%;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
  .prize_photo {
    position: relative;
    top: 170px;
    left: 100px;
    font-size: 46px;
    font-family: PingFang SC;
    color: #ffffff;
  }
}
.content_close2 {
  width: 70%;
  height: 200px;
  margin: 0 auto;
}
.logintext {
  position: absolute;
  top: 199px;
  left: 40px;
  font-size: 46px;
  font-family: PingFang SC;
  color: #ffffff;
}
.content_box3 {
  background-image: url("../assets/images/noprize.png");
  background-size: 869px 1105px;
  width: 869px;
  height: 1105px;
  text-align: center;
  .noprize_text {
    position: relative;
    top: 529px;
    font-size: 59px;
    font-weight: bold;
    font-family: Adobe Heiti Std;
    color: #ffffff;
  }
  .noprize_btn {
    background-image: url("../assets/images/onemore.png");
    background-size: 601px 148px;
    width: 601px;
    height: 148px;
    position: relative;
    top: 867px;
    left: 15%;
  }
}
.slide-enter-active {
  transition: all 4s ease;
}
.slide-enter-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(27vw);
}
</style>
